import {TableCell, TableRow, TableHead, Button} from "@material-ui/core"

export default ({charges, role, handleNewLineItem, isProcessed, uam}) => {

  return (
    <TableHead>
      <TableRow>
        <TableCell style={{fontSize: 24}} colSpan={role=="charges" ? 7 : 8}>{
          role == "charges" 
              ? `Charges (Total ${charges.filter(c => c.applicable).reduce((a,c) => (a + +c.amount) || 0, 0).toFixed(2)})`
              : `Usages`}
        </TableCell>
        <TableCell colSpan={2} align='right'>
          <Button disabled={isProcessed} onClick={_ => handleNewLineItem(role, uam ? uam.id : undefined)}>Add {role.replace(/s$/, "")} +</Button>
        </TableCell>
      </TableRow>
      {!!charges.length && 
        <TableRow>
          <TableCell sx={{ width: '100px' }}>Start Date</TableCell>
          <TableCell sx={{ width: '100px' }}>End Date</TableCell>
          <TableCell sx={{ width: '200px' }}>Description</TableCell>
          <TableCell sx={{ width: '100px' }}>Charge Type</TableCell>
          <TableCell align="center"  sx={{ width: '100px' }}>Amount</TableCell>
          <TableCell align="center"  sx={{ width: '100px' }}>Meter Number</TableCell>
          { role == "usages" && <TableCell align="center" sx={{ width: '40px' }}>Est?</TableCell> }
          <TableCell align="center"  sx={{ width: '200px' }}>UAM</TableCell>
          <TableCell align="center"  sx={{ width: '100px' }}>Include?</TableCell>
        </TableRow>
      }
    </TableHead>
  )
}
import {Select, MenuItem} from "@material-ui/core"
import {useContext, useState, useEffect} from "react"
import GPTBillContext from "../../../../contexts/GPTBillContext"
import {titleize} from "../../../helpers/string-helpers"
import { UTILITY_USAGE_TYPES } from "../../../helpers/constants"
export default function UsageTypeSelect({usage, liIndex, setGptBill, disabled}) {

    const handleUsageTypeChange = e => {
        const usageTypeId = e.target.value;
        setGptBill(prevState => {
            const newUsages = [...prevState.usages];
            const liIndex = newUsages.findIndex(u => u.id == usage.id)
            newUsages[liIndex].utility_usage_type_id = usageTypeId;
            return { ...prevState, usages: newUsages };
        });
    }

    return (
        <Select
            value={usage.utility_usage_type_id || 'blank'}
            onChange={handleUsageTypeChange}
            disabled={disabled}
        >
            <MenuItem key={'none'} value={'blank'}>Select Usage Type</MenuItem>
            {UTILITY_USAGE_TYPES.map(ct => <MenuItem key={ct.id} value={ct.id}>{titleize(ct.name)}</MenuItem>)}
        </Select>
    )
}
import {Button, Link, TextField, Typography, Select, MenuItem} from "@material-ui/core"
import {useState, useEffect, useContext} from "react";
import TextractContext from "../../contexts/TextractContext";
import axiosAuth from "../../utils/axiosWithAuth";
import Loader from "react-loader-spinner";
import SnackbarLoader from "./snackbar/SnackbarLoader"
import TextractIndexTable from "./TextractIndexTable"
import CompanySelector from "./CompanySelector";
import { useUser } from "../../contexts/UserContext";

const NULL_COMPANY_SELECTOR = {id: null, name: "No Company Selected"}
export default function Index() {
  const [loading, setLoading] = useState(false)
  const {user} = useUser()
  const {setFetchNew, textractBills, setTextractBills, selectedTextractBill, setSelectedTextractBill, setSelectedTextractBillId, setSnackbar, utilityCompanies} = useContext(TextractContext)

  const [selectedUploaderUtility, setSelectedUploaderUtility] = useState(NULL_COMPANY_SELECTOR)
  const handleImportS3Directory = e => {
    const dirName = prompt("Please provide the s3 directory name")

    axiosAuth().post("/admin/textract_bills/s3dir", {dirName})
      .then(r => {
        console.log(r)
        setSnackbar({message: `Started bulk processing ${r.data.fileCount} files from ${dirName}...`, severity: "success"})
      }).catch(err => console.log(err))
  }

  const onFileLoad = (e, utility_company_id) => {
    const {files} = e.target;
    console.log(files)
    if (e.target.files[0]) {
      if (utility_company_id == 'null' && !window.confirm(`You are about to upload files without specifying a utility company. If you know the provider from whom the bills came, please cancel and select a utility company from the selector before uploading.`)) {
        e.target.value = "";
        return
      }
      setLoading(true)
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append('fileData[]', files[i]);
      }
      formData.append("utility_company_id", utility_company_id == "null" ? null : utility_company_id)
      
       
      setSnackbar({message: "Extracting Data...", severity: "info",  loader: <SnackbarLoader /> })
      axiosAuth().post("admin/textract_bills/upload", formData, {headers: {"Content-Type": "multipart/form-data"}})
      .then(r => {
        console.log(r)
        setSnackbar({message: r.data.message || "Upload complete", severity: "success"})
        e.target.value = "";
        setLoading(false)
      }).catch(err => {
        setLoading(false)
        setSnackbar({message: "Extraction Failed.", severity: "error"})
        console.log(err)
        e.target.value = "";
        
      })
    }
  }

  return (
    <div>            
      {!user.os && <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 10}}>
        <Typography variant="p">Upload Files:&nbsp;</Typography>
        <CompanySelector 
          utilityCompanies={utilityCompanies}
          selectedUtilityCompany={selectedUploaderUtility}
          handleChange={(e, newValue)=> setSelectedUploaderUtility(newValue || NULL_COMPANY_SELECTOR)}
          style={{ width: 400, marginRight: 20}}
        />
        <TextField
          fillWidth
          inputProps={{
            multiple: true
          }}
          style={{display: 'block'}}
          name='fileData[]'
          type='file' 
          onChange={e => onFileLoad(e, selectedUploaderUtility.id)} />
        <Loader
          style={{opacity: loading ? 1 : 0, marginLeft: "8px"}}
          type="Grid"
          height={40}
          color = 'orange'
          width={40} /> 
        <Button onClick={handleImportS3Directory}>Import S3 Directory</Button>
      </div>}

      <TextractIndexTable
        setTextractBills={setTextractBills}
        setSelectedTextractBillId={setSelectedTextractBillId}
      />
    </div>
  )
}
import { DataGrid, GridToolbar  } from '@material-ui/data-grid';
import { Link, Typography } from '@material-ui/core';
import {useEffect, useState, useContext} from 'react';
import IntakeCard from '../IntakeCard';
import IntakeContext from '../../../../contexts/IntakeContext';
import PrimaryColorLoader from '../../../PrimaryColorLoader';
import { getFormattedDateUTC } from '../../../helpers/date-helpers';
import { useIntakeAccounts } from '../../../../contexts/AccountContext';
import { getEntry } from '../../../helpers/datagrid-helpers';

export default function IntakeTable({sources, filter, order}) {
  const {fetchAccounts} = useIntakeAccounts()
  const {accounts, setAccounts, markAsChecked, selectedIntakeAccount, setSelectedIntakeAccount} = useContext(IntakeContext)
  const [loadingIntake, setLoadingIntake] = useState(true)
  const [sortModel, setSortModel] = useState([]);
  const [filterModel, setFilterModel] = useState({ items: [] });
  const [doUpdate, setDoUpdate] = useState(null)

  const handleSortModelChange = (newModel) => {
    if (JSON.stringify(filterModel) != JSON.stringify(newModel.sortModel)) {
      setSortModel(newModel.sortModel);
    }
  };
  const handleFilterModelChange = (newModel) => {
    if (JSON.stringify(filterModel) != JSON.stringify(newModel.filterModel)) {
      setFilterModel(newModel.filterModel);
    }
  };


  useEffect(_ => {
    setAccounts([])
    setLoadingIntake(true)
    fetchAccounts({sources, filter, order}, _ => setLoadingIntake(false))

  },[])

  useEffect(_ => console.log(accounts), [accounts])

const INITIAL_COLUMNS = [
  { field: 'id', headerName: 'DataGrid uuid', width: 100, hide: true },
  {
      field: "Days Past Estimate",
      headerName: `Days Past Estimate`,
      headerAlign: "center",
      align: "center",
      width: 200,
  },
  {
      field: 'acct_num',
      headerName: 'Account Number', 
      width: 150,
      renderCell: (params) => {
          
          const row = getEntry(params)
          
          return  <div>
                      <Link target="_blank"
                          rel="noopener noreferrer"
                          href={`${process.env.REACT_APP_EP_BASE_URL}/admin/utility_accounts/${row.utility_account_id}/edit`}
                          className="grid-link">
                              {row.acct_num}
                      </Link>              
                  </div>
          }
  },
  {
      field: 'meter_nums',
      headerName: 'Meters', 
      width: 250,

  },
  {
      field: 'Utility',
      headerName: 'Utility', 
      width: 250,
      renderCell: (params) => {
          
        const row = getEntry(params)
        
        return  <div>
                    <Link target="_blank"
                        rel="noopener noreferrer"
                        href={`${process.env.REACT_APP_EP_BASE_URL}/admin/utility_companies/${row.utility_company_id}/edit`}
                        className="grid-link">
                            {row.Utility}
                    </Link>              
                </div>
        }

  },
  { 
    field: 'online_access_url', 
    headerName: 'Website', 
    width: 400,
    renderCell: (params) => {
          
      const row = getEntry(params)
      
      return  <div>
                  <Link target="_blank"
                      rel="noopener noreferrer"
                      href={row.online_access_url}
                      className="grid-link">
                          {row.online_access_url}
                  </Link>              
              </div>
      }
  },
  { 
    field: 'online_access_username', 
    headerName: 'Username', 
    width: 200,
  },
  { 
    field: 'last_online_check_at', 
    headerName: 'Last Checked', 
    width: 200,
    valueFormatter: function(v) { 
      return v.row.last_online_check_at  
      ? getFormattedDateUTC(v.row.last_online_check_at)
      : ""
    }
  },
  { 
    field: 'online_follow_up_date', 
    headerName: 'Follow Up', 
    width: 200,
    valueFormatter: function(v) { 
      return v.row.online_follow_up_date  
      ? getFormattedDateUTC(v.row.online_follow_up_date)
      : ""
    }
  },
  { 
    field: 'latest_invoice_date', 
    headerName: 'Latest Invoice Date', 
    width: 200,
    valueFormatter: function(v) { 
      return v.row.latest_invoice_date  
      ? getFormattedDateUTC(v.row.latest_invoice_date)
      : ""
    }
  },
  { 
    field: 'delivery_method', 
    headerName: 'Delivery Method', 
    width: 200
  },
  { 
    field: 'auto_process_in_invoyage', 
    headerName: 'Auto', 
    width: 60,
    valueFormatter: function(v) { 
      return v.row.auto_process_in_invoyage  
      ? "🤖"
      : " "
    }
  },
  { 
    field: 'utility_company_id', 
    headerName: 'Utility Company ID', 
    width: 200,
    hide: true
  },
  { 
    field: 'utility_account_id', 
    headerName: 'Account ID', 
    width: 200,
    hide: true
  },
]

  const getRowClassName = params => `super-app-theme--${params.getValue(params.id, 'property_name')}`
  const [columns, setColumns] = useState(INITIAL_COLUMNS)
  const handleColumnVisibilityChange = (params) => {
    setColumns(currentColumns =>
      currentColumns.map((column) => {
        if (column.field === params.field) {
          return { ...column, hide: !params.isVisible };
        }
        return column;
      }),
    );
  };
  const [selectionModel, setSelectionModel] = useState([]);
  const handleSelectionChange = newSelection => {
    const dataGridId = newSelection.selectionModel[0]
    const foundAccount = accounts.find(r => r.id == dataGridId)
    setSelectedIntakeAccount(foundAccount)
  }
  const getSortedRows = () => {
    if (!sortModel.length) return accounts;

    const sortedAccounts = [...accounts].sort((a, b) => {
      for (let { field, sort } of sortModel) {
        if (a[field] < b[field]) return sort === 'asc' ? -1 : 1;
        if (a[field] > b[field]) return sort === 'asc' ? 1 : -1;
      }
      return 0;
    });
    console.log("sorted accounts", sortedAccounts)
    return sortedAccounts
  };
  const getNextRowId = () => {
    if (accounts.length === 0) return; // Guard against no accounts
    const sortedRows = getSortedRows();
    console.log(selectionModel[0])
    console.log(sortedRows[0].id)
    const currentSelectedIndex = sortedRows.findIndex(row => row.id == selectionModel[0]);
    console.log("Current index", currentSelectedIndex)
    const nextRowIndex = currentSelectedIndex + 1 < sortedRows.length ? currentSelectedIndex + 1 : 0;
    const nextRowId = sortedRows[nextRowIndex].id;
    return nextRowId
    
  }
  const selectRow = (nextRowId) => {
    setSelectionModel([nextRowId]);
  };
  
  return (
    <div className="intake-table">
      {!!accounts.length 
      ? <> 
          <IntakeCard fetchParams={{sources, filter, order}} selectRow={selectRow} getNextRowId={getNextRowId} markAsChecked={markAsChecked} account={selectedIntakeAccount} setAccount={setSelectedIntakeAccount} open={filter == "open"} />
          <DataGrid
            rows={accounts}
            
            pageSize={20}
            rowsPerPageOptions={[20,50,100]}
            getRowClassName={getRowClassName}
            disableColumnMenu
            autoHeight
            selectionModel={selectionModel}
            onSelectionModelChange={(newSelection) => {
              handleSelectionChange(newSelection)
              setSelectionModel(newSelection.selectionModel);
            }}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            filterModel={filterModel}
            onFilterModelChange={handleFilterModelChange}
            columns={columns}
            onColumnVisibilityChange={handleColumnVisibilityChange}
            components={{
              Toolbar: GridToolbar,
            }}
          />
        </>
      : loadingIntake 
        ? <Typography variant="h2">Loading... <PrimaryColorLoader width={40} height={40} /></Typography>
        : <Typography style={{marginTop: 30}} variant="h2">{filter == "open" ? "All caught up! 🎉" : "No accounts here!"}</Typography>
        
      }

    </div>
  )
}








import { Select, MenuItem } from "@material-ui/core"
import { useContext, useState, useEffect } from "react"
import { findMatchingUtility } from "./UamHelpers";
import TextractContext from "../../../../contexts/TextractContext"

export default function UamSelect({li, liType, setGptBill, disabled, uams}) {
    const {selectedTextractBill, selectedTextractBillId} = useContext(TextractContext)
    const [selectedUam, setSelectedUam] = useState(null)
    const handleUamChange = e => {
        const uamId = e.target.value;
        setGptBill(prevState => {
            if (liType == "charge") {
                const newCharges = [...prevState.charges];
                const liIndex = prevState.charges.findIndex(c => c.id == li.id)
                newCharges[liIndex].utility_account_meter_id = uamId;
                return { ...prevState, charges: newCharges };       
            } else {
                const newUsages = [...prevState.usages];
                const liIndex = prevState.usages.findIndex(u => u.id == li.id)
                newUsages[liIndex].utility_account_meter_id = uamId;
                return { ...prevState, usages: newUsages };
            }
        });
    }

    useEffect(_ => {
        if (li.utility_account_meter_id && !uams.find(uam => uam.id == li.utility_account_meter_id)) {
            handleUamChange({target: {value: null}})
        }
        if (uams.length && !li.utility_account_meter_id) {
            console.log("Pairing LI with uam:", li)
            console.log("uams", li)
            console.log("selectedTextractBill", selectedTextractBill.utility_company_id)

            const newSelection = findMatchingUtility(li, uams, selectedTextractBill)
            console.log("Pairing to", newSelection)
            setSelectedUam(newSelection && newSelection.id)
            handleUamChange({target: {value: newSelection.id}})
        }
    },[uams])

    return (
        !!uams.length && 
        <>
            <Select
                value={li.utility_account_meter_id || selectedUam}
                onChange={handleUamChange}
                disabled={disabled}
                style={{width: 180}}
            >
                {uams.map(uam => <MenuItem key={uam.id} value={uam.id}>{uam.meter_num ||
                uam.um_meter_num} - {uam.display_name} {!!(uam.association_type == "STANDARD") && `(${uam.association_type})`} {!(uam.status == "Active") && `(${uam.status.toUpperCase()})`}</MenuItem>)}
            </Select>

        </>
    )
}
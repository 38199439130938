import {Typography, Alert, Switch, FormControlLabel, Box} from "@material-ui/core"
import axiosAuth from "../../../utils/axiosWithAuth"
import {useEffect, useState, useContext, useRef, useCallback } from "react"
import {useHistory} from 'react-router-dom'
import GPTBillData from "./GPTBillData"
import GPTBillContext from "../../../contexts/GPTBillContext"
import BillControls from "./BillControls"
import LineItemTable from "./form/tables/LineItemTable"
import TextractContext from "../../../contexts/TextractContext"
import ErrorMessages from "./form/ErrorMessages"
import SnackbarLoader from "../snackbar/SnackbarLoader"
import GPTResponse from "./form/GPTResponse"
import UamNotesModal from "../../request-emails/UamNotesModal"
import { buildLineItemGroups, buildGptBillFromUtilityBill } from "./form/formatters/bill-formatters"
import LineItemGroup from "./form/tables/LineItemGroup"
import { AlbumSharp } from "@material-ui/icons"

import ServerErrors from "./ServerErrors"
import { debounce } from 'lodash';
import ViewBillSwitch from "./ViewBillSwitch"

const getBaseGptBill = tb => ({
  start_date: null,
  end_date: null,
  charges: [],
  usages: [],
  meters: [],
  id: 0,
  start_date: null,
  end_date: null,
  textract_bill_id: tb.id,
  response: "",
  archived: false,
  total_cost: null,
  invoice_date: tb.invoice_date,
  acct_num: tb.acct_num,
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString()
})

export default function GPTBill({bill, setBill}) {
  const {uams, setUams, setSnackbar, selectedTextractBill, setSelectedTextractBill} = useContext(TextractContext)
  const history = useHistory()
  const [selectedUam, setSelectedUam] = useState(null)
  const [lineItemGroups, setLineItemGroups] = useState([])
  const [errors, setErrors] = useState([])
  const [loading, setLoading] = useState(false)
  const [gptBill, setGptBill] = useState(bill.gpt_bill)
  const [utilityCompanies, setUtilityCompanies] = useState([])
  const [accounts, setAccounts] = useState([])
  const [selectedAccount, setSelectedAccount] = useState(null)
  const [selectedUtilityCompany, setSelectedUtilityCompany] = useState(null)
  const [timeoutDone, setTimeoutDone] = useState(false)
  const [loadDelayOn, setLoadDelayOn] = useState(true)
  const [serverErrors, setServerErrors] = useState(null)
  const [savedBillLineItemGroups, setSavedBillLineItemGroups] = useState([])
  const [savedBillAsGptBill, setSavedBillAsGptBill] = useState(null)
  const [showSavedBill, setShowSavedBill] = useState(false)
  const [processingNotes, setProcessingNotes] = useState([])

  const previousGptBillId = useRef();

  useEffect(_ =>  {
    if (uams && uams.length) {
      setProcessingNotes(
        uams.flatMap(uam => uam.notes).filter(
          note => /\[processing\]/i.test(note.content)
        ) 
      )
    } else {
      setProcessingNotes([])
    }
  }, [uams])

  useEffect(_ => {
    if (bill.gpt_bill) {
      if (previousGptBillId.current !== bill.gpt_bill.id) {
        setTimeoutDone(false);
        setLoadDelayOn(true);
        previousGptBillId.current = bill.gpt_bill.id;
        
      }
      setGptBill(bill.gpt_bill)
      console.log(bill.gpt_bill)
    } else {
      setGptBill(getBaseGptBill(bill))
      
    }
    setLineItemGroups([])
    setTimeout(_ => setTimeoutDone(true), 700)
    setTimeout(_ => setLoadDelayOn(false), 300)
  },[bill])

  const fetchValidations = gptBill  => {
    const lineItems = [...gptBill.charges, ...gptBill.usages]
    if (gptBill && lineItems.length && lineItems.every(li => li.utility_account_meter_id)) {
      axiosAuth().post("/admin/openai/get_validation_errors", {bill: gptBill})
        .then(r => setServerErrors(r.data))
        .catch(r => {
          setServerErrors(null)
          console.log("validation errors error", r)
        })
    }
  }

  const debouncedFetchValidations = useCallback(debounce((gptBill) => {
    fetchValidations(gptBill)
  }, 300), []);

  useEffect(_ => {
    if (gptBill && !bill.processed) {
      debouncedFetchValidations(gptBill)
    }
  }, [gptBill, debouncedFetchValidations, bill])

  useEffect(_ => {
    if (!gptBill) {
      setLineItemGroups([])
      return
    }
    if (!loadDelayOn) {
      setLineItemGroups(
        buildLineItemGroups(gptBill, uams || []), 
      )
    }
  }, [gptBill, uams, loadDelayOn])

  useEffect(_ => {
    const newSavedBillAsGptBill = buildGptBillFromUtilityBill(selectedTextractBill)
    if (selectedTextractBill.ep_bill) {
      setShowSavedBill(true)
    } else {
      setShowSavedBill(false)
    }
    if (newSavedBillAsGptBill) {
      setSavedBillAsGptBill(newSavedBillAsGptBill)

      setSavedBillLineItemGroups(
        buildLineItemGroups(newSavedBillAsGptBill, uams || [])
      )
    }
  }, [selectedTextractBill, uams])

  const handleChargeChange = (li, event) => {
    const { name, value } = event.target;
    setGptBill(prevState => {
      const newCharges = [...prevState.charges];
      const index = newCharges.findIndex(u => u.id == li.id)
      newCharges[index][name] = value;
      return { ...prevState, charges: newCharges };
    });
  };
  const handleUsageChange = (li, event) => {
    const { name, value } = event.target;
    setGptBill(prevState => {
      const newUsages = [...prevState.usages];
      const index = newUsages.findIndex(u => u.id == li.id)
      newUsages[index][name] = value;
      return { ...prevState, usages: newUsages };
    });
  };

  const handleReprocess = tb => {
    setLoading(true)
    axiosAuth().post("/admin/openai/revert", {textract_bill_id: tb.id})
    .then(r => {
      setLoading(false)
      console.log(r);
      if (r.data.success) {
        setSnackbar({message: `Reverted Invoyage #${bill.id}`, severity: "info",  loader: null })
        setBill(prevState => ({...prevState, status: "GPT_PARSED"}))
        axiosAuth().get(`/admin/textract_bills/${ tb.id}`)
        .then(r => {
          setSelectedTextractBill(r.data)
        })
      }
    })
    .catch(e => {
      setSnackbar({message: `Failed to revert Invoyage #${bill.id}`, severity: "error",  loader: null })
      setLoading(false)
    })
  }
  
  const handleSkip = (tb) => {
    setLoading(true)
    axiosAuth().post("/admin/openai/skip", {textract_bill_id: tb.id})
    .then(r => {
      setLoading(false)
      console.log(r);
      r.data.success && setBill(prevState => ({...prevState, status: "PROCESSED_FORCE"}))
      setSnackbar({message: `Skipped Invoyage #${bill.id}`, severity: "info",  loader: null })
      history.push(`/invoyage/${tb.queue.next || tb.queue.prev || ""}`)
    })
    .catch(e => {
      setSnackbar({message: `Could not skip Invoyage #${bill.id}`, severity: "error",  loader: null })
      setLoading(false)
    })
  }

  const handleSave = (b, tb) => {
    setLoading(true)
    setErrors([])
    console.log("Bill from handleSave", b)
    setSnackbar({message: `Saving #${bill.id}...`, severity: "info",  loader: <SnackbarLoader /> })
    axiosAuth().post("/admin/openai/create_bill", {bill: b})
    .then(r => {
      console.log(r)
      setLoading(false)
      if (r.data.errors) {
        setErrors(r.data.errors)
        setSnackbar({message: `Could not save Invoyage #${bill.id}`, severity: "error",  loader: null })
      } else if (r.data.success) {
        setBill(prevState => ({...prevState, status: "PROCESSED_MANUAL"}))
        axiosAuth().get(`/admin/textract_bills/${b.textract_bill_id}`)
        .then(r => {
          setSnackbar({message: `Saved Invoyage #${bill.id}`, severity: "success",  loader: null })
          setSelectedTextractBill(r.data)
          history.push(`/invoyage/${tb.queue.next || tb.queue.prev || ""}`)
          
        })
          
      }
    })
    .catch(e => {
      setSnackbar({message: `Could not save Invoyage #${bill.id}`, severity: "error",  loader: null })
      setLoading(false)
      console.log("ERROR")
      console.log(e.response)
    })
  }

  const handleNewLineItem = (lineItemType, uamId=null) => {
    const typeKey = `utility_${lineItemType.replace(/s$/,"")}_type_id` 
    setGptBill(prevState => ({
      ...prevState,
      [lineItemType]: [...prevState[lineItemType], {start_date: gptBill.start_date, end_date: gptBill.end_date, amount: 0, [typeKey]: null, applicable: true, utility_account_meter_id: uamId, id: Date.now()}]
    }))
  }
  const lineItemIsValid = li => (!li.applicable || (li.applicable && (li.utility_charge_type_id || li.utility_usage_type_id)))
  && li.utility_charge_type_id != "blank"
  && li.utility_usage_type_id != "blank"
  && li.start_date && li.end_date
  && new Date(li.start_date) <= new Date(li.end_date)

  const billIsValid = gptBill => {
    const isValid = !!(gptBill.charges.filter(c => c.applicable).every(c => lineItemIsValid(c)) &&
    gptBill.usages.filter(u => u.applicable).every(u => lineItemIsValid(u)) &&
    gptBill.invoice_date)
    return isValid 
  }
  

  return (
    !!gptBill
      ? 
      <div>
        <GPTBillContext.Provider value={{showSavedBill, savedBillLineItemGroups, gptBill, setGptBill, serverErrors, setServerErrors, setSelectedUam, lineItemIsValid, billIsValid, handleNewLineItem, setGptBill, uams, setUams, utilityCompanies, setUtilityCompanies, accounts, setAccounts, selectedAccount, setSelectedAccount, selectedUtilityCompany, setSelectedUtilityCompany}}>
          {
          !!bill.ep_bill &&     
            <ViewBillSwitch 
              showSavedBill={showSavedBill}
              setShowSavedBill={setShowSavedBill}
            />
          }
          {!bill.gpt_bill && <Typography variant="h4">
            {bill.tables
              ? `Click "Parse with GPT" to analyze line items`
              : `You must extract tables to parse with GPT`
            }
          </Typography>}
          { !!processingNotes.length && 
            processingNotes.map(note => 
            <Alert severity="info">
              {note.content}
            </Alert>)
          }

          { bill.status == "ERROR" && 
            <Alert severity="error">
                Last Automation Error: {selectedTextractBill.last_error_cause}
            </Alert>
          }
          
          <GPTBillData isDisabled={bill.status.includes("PROCESSED_")} textractBill={bill} gptBill={gptBill} setGptBill={setGptBill} />
          <div style={{opacity: timeoutDone ? 1 : 0, transition: '.3s', transform: `translateX(${timeoutDone ? 0 : '-20px'})`}}>



            {uams.length && lineItemGroups.length ? 
            <>
              <div class='gpt-bill-container' style={{display: showSavedBill ? "none" : "block"}}>
                {lineItemGroups.map(
                  (lig, idx) => 
                  <LineItemGroup 
                  idx={idx}
                  key={`${bill.id}-line-item-group-${idx}-${lig.name}`}
                  group={lig}
                  handleChargeChange={handleChargeChange}
                  handleUsageChange={handleUsageChange}
                  gptBill={gptBill}
                  setGptBill={setGptBill}
                  uam={lig.utility_account_meter}
                  uams={uams}
                  bill={bill}
                  handleNewLineItem={handleNewLineItem}
                  />
                )} 
              </div>
              <div class='ep-bill-container' style={{display: showSavedBill ? "block" : "none"}}>
                {!!bill && !!bill.ep_bill && savedBillLineItemGroups.map(
                  (lig, idx) => 
                    <LineItemGroup 
                      idx={idx}
                      key={`${bill.id}-line-item-group-${idx}-${lig.name}`}
                      group={lig}
                      handleChargeChange={handleChargeChange}
                      handleUsageChange={handleUsageChange}
                      gptBill={savedBillAsGptBill}
                      setGptBill={setSavedBillAsGptBill}
                      disabled={true}
                      uam={lig.utility_account_meter}
                      uams={uams}
                      bill={bill}
                      handleNewLineItem={handleNewLineItem}
                    />
                )} 
              </div>
            </>
            : <>
              <LineItemTable
                isDisabled={bill.status.includes("PROCESSED_")}
                role="charges"
                lineItems={gptBill.charges}
                setGptBill={setGptBill}
                handleChange={handleChargeChange}
                uams={uams}
                handleNewLineItem={handleNewLineItem}
              />
              <LineItemTable
                isDisabled={bill.status.includes("PROCESSED_")}
                role="usages"
                lineItems={gptBill.usages}
                setGptBill={setGptBill}
                handleChange={handleUsageChange}
                uams={uams}
                handleNewLineItem={handleNewLineItem}
              />
            </>}
            <GPTResponse response={gptBill.response} confidence={gptBill.confidence} />
          </div>
          <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start'}}>
            {serverErrors && <ServerErrors />}
            <BillControls
              loading={loading}
              handleReprocess={handleReprocess}
              gptBill={gptBill}
              billIsValid={billIsValid}
              textractBill={bill}
              handleSave={handleSave}
              handleSkip={handleSkip}
            />
          </div>
          <ErrorMessages errors={errors} />
          <UamNotesModal 
              request={selectedUam}
              openModal={!!selectedUam}
              handleClose={ _ => setSelectedUam(null) }
          />
        </GPTBillContext.Provider>
      </div>
      : <>
        
        <BillControls
            loading={loading}
            handleReprocess={handleReprocess}
            textractBill={bill}
            handleSave={handleSave}
            handleSkip={handleSkip}
            onlySkip={true}
        />

      </>
  )

}

import { Tabs, Tab, AppBar, useTheme  } from "@material-ui/core"
import {a11yProps} from "../helpers/a11y"
import {useUser} from "../../contexts/UserContext"

export default function ModeTabs({index, setIndex}) {
  const theme = useTheme();
  const {user} = useUser()
  const borderColor = theme.palette.mode === 'light' ? '#d6d6d6' : '#424242';
  return(
    !user.os && <AppBar position="static" color="default">
      <Tabs   
        centered 
        indicatorColor="primary" 
        value={index} 
        style={{borderBottom: `1px solid ${borderColor}`}}
        onChange={(e, val) => setIndex(val)}>
        <Tab label="Intake" {...a11yProps(0)} />
        <Tab label="Processing" {...a11yProps(1)} />
      </Tabs>
    </AppBar>
  )
}
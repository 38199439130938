export const UTILITY_CHARGE_TYPES = [
  {id:1,	name:"usage_cost"},
  {id:2,	name:"tax_cost"},
  {id:3,	name:"other_applied_cost"},
  {id:4,	name:"other_unapplied_cost"},
  {id:5,	name:"interim_rate_cost"},
  {id:6,	name:"service_cost"},
  {id:7,	name:"demand_cost"}
]

export const UTILITY_USAGE_TYPES = [
  {id:1,	name:"general_usage"},
  {id:2,	name:"demand_consumption"}
]

export const EP_ORANGE = "#fb8c00"

export const EP_ORANGE_HIGHLIGHT = "#ffc378"

export const HIGHLIGHTED_ROW_STYLES = {
  backgroundColor: EP_ORANGE_HIGHLIGHT, 
  border: "3px solid #ce0000",
}

export const DARK_HIGHLIGHTED_ROW_STYLES = {
  backgroundColor: '#955200', 
  border: "3px solid darkred",
}

export const BILL_STATUS_OPTIONS = {
  ERROR: "Error",
  ALL_PROCESSED: "All Processed",
  PROCESSED_AUTO: "Auto Imported",
  PROCESSED_MANUAL: "Manually Imported",
  PROCESSED_FORCE: "Skipped",
  UNPROCESSED: "Unprocessed",
  ALL: "All Statuses",

}
import { TextField, useTheme } from "@material-ui/core";
import { useState, useContext, useEffect } from "react";
import GPTBillContext from "../../../../contexts/GPTBillContext";
import TextractContext from "../../../../contexts/TextractContext";

function DateInput(props) {
    const theme = useTheme()
    const {setSelectedTextractBill} = useContext(TextractContext)
    const {setGptBill, showSavedBill} = useContext(GPTBillContext)
    const [inputStyle, setInputStyle] = useState({});
    const [dateValue, setDateValue] = useState((showSavedBill && props.textractBill.ep_bill) 
                      ? props.textractBill.ep_bill.invoice_date.split("T")[0]
                      : props.textractBill.invoice_date 
                        ? props.textractBill.invoice_date.split("T")[0] 
                        : props.gptBill.invoice_date 
                          ?  props.gptBill.invoice_date.split("T")[0] 
                          : "")
    const handleChange = e => {
      console.log(props.gptBill)
      const changes = {[e.target.name]: e.target.value}
      console.log("New value", e.target.value)
      setGptBill(prevState => ({
        ...prevState,
        ...changes
      }))
      setDateValue(e.target.value)
    }

    useEffect(_ => {
      console.log("validating date")
      validateDate(dateValue, props.gptBill)
    }, [dateValue, props.gptBill])

    const validateDate = (date, gptBill) => {
      const endDate = [...gptBill.charges, ...gptBill.usages].filter(li => li.end_date).map(li => li.end_date).sort().pop()
      const currentDate = new Date();
      const certainDate = endDate ? new Date(endDate) : null
      const enteredDate = new Date(date);


      if (!date) {
          // If no date is entered
          setInputStyle({
              backgroundColor: theme.palette.mode === 'light' ? '#ffc6c6' : '#6f1414',
              borderColor: 'red'
          });
      } else if (enteredDate > currentDate) {
        setInputStyle({
          backgroundColor: theme.palette.mode === 'light' ? 'yellow' : '#daa520aa',
        });
      } 
      
      else if (certainDate && enteredDate < certainDate) {
        setInputStyle({
          backgroundColor: theme.palette.mode === 'light' ? 'yellow' : '#daa520aa',
        });
      } else {
        // If the date is valid and not after the certain date
        setInputStyle({});
      }
  };


    return (
      <TextField
        disabled={props.disabled}
        name={props.name}
        type='date'
        value={dateValue}
        style={inputStyle}
        onChange={handleChange}
        InputLabelProps={{
          shrink: true,
        }}
      />
    );
  }
  
  export default DateInput;
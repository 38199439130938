import {Paper, TableContainer, TableRow, TextField, TableCell, TableBody, Table, Divider} from "@material-ui/core"
import ApplicableCheckbox from "../ApplicableCheckbox"
import ChargeTypeSelect from "../ChargeTypeSelect"
import UsageTypeSelect from "../UsageTypeSelect"
import TableHeader from "./TableHeader"
import UamSelect from "../UamSelect"
import GPTBillContext from "../../../../../contexts/GPTBillContext"
import { useContext, useCallback, useState } from "react"
import UserContext from "../../../../../contexts/UserContext"
import AmountInput from "./AmountInput";

export default ({role, lineItems, handleChange,gptBill, setGptBill, uams, handleNewLineItem, isDisabled, uam }) => {
  const {lineItemIsValid } = useContext(GPTBillContext)
  const {activeTheme} = useContext(UserContext)


  
  return ( 
    <>
      <TableContainer style={{marginTop: 5}} component={Paper} elevation={5}>
        <Table sx={{ minWidth: 800 }} aria-label="Tables Data">
          <TableHeader isProcessed={isDisabled} handleNewLineItem={handleNewLineItem} charges={lineItems} uam={uam} role={role} />
          <TableBody>
            {lineItems.map((li, idx) => (
              <TableRow
                key={`${uam && uam.id}-${role}-${li.id || idx}`}
                sx={{ '&:last-child td, &:last-child th': { border: 0 }, opacity: li.applicable ? 1 : 0.5, background: !lineItemIsValid(li) ? activeTheme.palette.mode == "dark" ? "#6f1414" : '#ffc6c6' : 'none' }}
              >
                <TableCell align="left">
                  <TextField
                    disabled={isDisabled}
                    type="date"
                    name="start_date"
                    error={!li.start_date || new Date(li.start_date) > new Date(li.end_date)}
                    value={li.start_date && li.start_date.split("T")[0]}
                    onChange={e => handleChange(li, e)}
                    />
                  {/* {getFormattedDateTZ(li.start_date)} */}
                </TableCell>
                <TableCell align="left">
                  <TextField
                    disabled={isDisabled}
                    type="date"
                    name="end_date"
                    error={!li.end_date || new Date(li.start_date) > new Date(li.end_date)}
                    value={li.end_date && li.end_date.split("T")[0]}
                    onChange={e => handleChange(li, e)}
                  />
                </TableCell>
                <TableCell align="left">{li.description}</TableCell>
                <TableCell align="left">
                  { role == "charges" 
                    ? <ChargeTypeSelect disabled={isDisabled} charge={li} liIndex={idx} setGptBill={setGptBill} />
                    : <UsageTypeSelect disabled={isDisabled} usage={li} liIndex={idx} setGptBill={setGptBill} />
                  }
                </TableCell>
                <TableCell align="center">
                  <div style={{display: 'flex', alignItems: 'center', justifyContent: role == 'usages' ? 'flex-end' : 'center'}}>
                    <AmountInput 
                      isDisabled={isDisabled}
                      handleChange={handleChange}
                      li={li}
                    />
                    <span style={{width: 50, textAlign: 'left'}}>&nbsp;&nbsp;
                      { role == "usages" && li.measurement_unit}
                    </span>

                    
                  </div>
                </TableCell>
                <TableCell align="center">{li.meter_num}</TableCell>
                {role == "usages" && <TableCell align="center">{li.estimated ? "✅" : ""}</TableCell>}
                <TableCell align="center">
                  <UamSelect 
                    disabled={isDisabled}
                    uams={uams}
                    li={li}
                    liType={role.replace(/s$/, "")} 
                    liIndex={idx} 
                    setGptBill={setGptBill}
                    gptBill={gptBill}
                    
                  />
                </TableCell>
                <TableCell align="center">
                  <ApplicableCheckbox 
                    disabled={isDisabled} 
                    li={li}
                    liType={role.replace(/s$/, "")} 
                    liIndex={idx} 
                    setGptBill={setGptBill}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
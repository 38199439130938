import {useState, useContext, useEffect, useMemo} from "react";
import { Tooltip, IconButton, TextField, Select, MenuItem, Typography, Button, Autocomplete } from "@material-ui/core";
import axiosAuth from "../../../utils/axiosWithAuth";
import TextractContext from "../../../contexts/TextractContext";
import {HelpOutline} from '@material-ui/icons';
import InvoyageTooltip from "../bill/InvoyageTooltip";
export default ({}) => {
  const [promptNotes, setPromptNotes] = useState([])
  const {utilityCompanies, setSnackbar} = useContext(TextractContext)
  const [selectedNote, setSelectedNote] = useState(null)
  const [selectedCompany, setSelectedCompany] = useState(null)
  const handleChange = e => setSelectedNote({...selectedNote, [e.target.name]: e.target.value})
  useEffect(_ => {
    if (!selectedNote) return
    setSelectedCompany(utilityCompanies.find(company => company.id === selectedNote.attachable_id))

  }, [selectedNote, utilityCompanies])
  useEffect(_ => {
    axiosAuth().get("/admin/textract_bills/prompts")
      .then(r => {
        setPromptNotes(r.data)
        setSelectedNote(r.data[0] || null)
      })
      .catch(err => console.log(err.response))
  }, [])

  const handleSave = _ => {
    axiosAuth().put("/admin/textract_bills/prompts", selectedNote)
      .then(r => {
        setPromptNotes(r.data)
        setSnackbar({message: "Saved Prompt", severity: "success" })
      })
      .catch(console.error)
  }

  const handleNoteChange = e => setSelectedNote(promptNotes.find(pn => pn.id == e.target.value))

  const handleNewNote = _ => {
    axiosAuth().post("/admin/textract_bills/prompts/new")
    .then(r => {
      setPromptNotes([...promptNotes, r.data])
      setSelectedNote(r.data)
    }).catch(err => console.log(err.response))
  }

  const sortedPromptNotes = useMemo(() => 
    promptNotes.sort((a, b) => a.name.localeCompare(b.name)),
    [promptNotes]
  );

  const handleCompanyChange = (e, newValue) => {
    console.log("newValue", newValue)
    console.log({
      ...selectedNote,
      attachable_type: "utility_company",
      attachable_id: newValue.id
    })
    setSelectedNote({
      ...selectedNote,
      attachable_type: "utility_company",
      attachable_id: newValue.id
    })
  }

  const handlePromptAutocompleteChange = (event, newValue) => {
    // Construct a simulated event to conform with how the original handleNoteChange expects the input
    handleNoteChange({
      target: {
        value: newValue ? newValue.id : '',
      },
    });
  };
  return (
    <div className="prompt-notes-editor">
      <Typography variant='h3'>AI Instructions
      <InvoyageTooltip title="Add special instructions in natural language for how to parse a specfic Utility's tables. Use this to tweak the line items returned by GPT. If the Textract Tables contain all the numbers you want, custom insructions here might help the bill. You can use positional words like 'Above' or 'Near' when describing where to find desired data." arrow>
        <IconButton color='primary'>
          <HelpOutline />
        </IconButton>
      </InvoyageTooltip>
      </Typography> 
      <br/>
      <Button onClick={handleNewNote}>Create New +</Button>
      <Autocomplete
        id="note-autocomplete"
        options={sortedPromptNotes}
        getOptionLabel={(option) => option.name}
        style={{ width: 550, margin: '5px auto' }}
        value={sortedPromptNotes.find(note => note.id === (selectedNote && selectedNote.id)) || null}
        onChange={handlePromptAutocompleteChange}
        renderInput={(params) => (
          <TextField {...params} label="Note" variant="outlined" />
        )}
        getOptionSelected={(option, value) => option.id === value.id}
      />
      {selectedNote && 
        <div className="note-form">
          <TextField
            value={selectedNote.name}
            label="Title"
            onChange={handleChange}
            name="name"
          />
          <TextField
            value={selectedNote.prompt_notes}
            label="Edit Notes"
            onChange={handleChange}
            name="prompt_notes"
            multiline
            rows={15}
          />
          <Autocomplete
            options={utilityCompanies}
            value={selectedCompany}
            onChange={handleCompanyChange}
            getOptionLabel={(option) => {console.log(option); return option.name}}
            style={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Utility" variant="outlined" />}
          /> 
          <Button disabled={!selectedCompany} variant="contained" onClick={handleSave}>Save</Button>
        </div>
      }
    </div>
  )
}
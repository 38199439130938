import {Typography, Table, TableRow, TableBody, TableContainer, TableHead, TableCell, Paper} from "@material-ui/core"
import {useEffect, useState} from 'react';
import axiosAuth from "../../../utils/axiosWithAuth"

export default function Tables({bill}) {
  const [tables, setTables] = useState(null)
  useEffect(_ => {
    axiosAuth().get(`/admin/textract_bills/${bill.id}/tables`)
      .then(r => setTables(r.data.tables))
      .catch(console.error)
  },[])
  return (
    !!tables 
      && <div>
        <Typography variant="h4">Tables</Typography>
        {tables.sort((a,b) => a.ord - b.ord).map((t, idx) => {
          return  <TableContainer style={{marginTop: 10}} component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="Tables Data">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={5}>{t.title} - Page {t.page}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {t.rows.map((row) => (
                  <TableRow
                    key={""}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    {row.map(cell => <TableCell align="left">{cell.content}</TableCell>)}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        })}

      </div>
  )

}